import {
  doc,
  getDocs,
  getDoc,
  collection,
  query,
  limit,
  increment,
  updateDoc,
  where,
  orderBy,
  startAfter,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "config/firebase";

const getDocument = (id) => {
  return doc(db, "programs", id);
};

export const getPrograms = async (last, category) => {
  try {
    // Query to get the programs
    let q = category
      ? query(
          collection(db, "programs"),
          where("categories", "array-contains", category),
          orderBy("createdAt", "desc"),
          ...(last ? [startAfter(last)] : []),
          limit(10)
        )
      : query(
          collection(db, "programs"),
          orderBy("createdAt", "desc"),
          ...(last ? [startAfter(last)] : []),
          limit(10)
        );

    const documentSnapshots = await getDocs(q);

    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    const programs = documentSnapshots.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    // Get the total count of programs in the collection
    let countQuery = category
      ? query(
          collection(db, "programs"),
          where("categories", "array-contains", category),
          orderBy("createdAt", "desc"),
        )
      : query(
          collection(db, "programs"),
          orderBy("createdAt", "desc"),
        );
    const countSnapshot = await getCountFromServer(countQuery);
    const totalProgramsCount = countSnapshot.data().count;

    // Return programs, last document, and total count of programs
    return { programs, last: lastVisible, totalProgramsCount };
  } catch (error) {
    console.error(`Error while getting programs: ${error}`);
    return error;
  }
};

export const getProgramById = async (id) => {
  const docRef = getDocument(id);
  const docSnap = await getDoc(docRef);
  const program = docSnap.data();
  if (docSnap.exists) {
    updateDoc(getDocument(id), { views: increment(1) });
    return { id: docSnap.id, ...program };
  }
};

export const getProgramsByCategory = async (category) => {
  try {
    const q = query(
      collection(db, "programs"),
      where("categories", "array-contains", category),
      // where("published", "==", true)
    );
    const docSnapshots = await getDocs(q);
    const programs = docSnapshots.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });

    return programs;
  } catch (error) {
    console.error(`Error while getting programs by category ${error}`);
    return error;
  }
};
